import React, { Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { useState } from 'react';
import { useEffect } from 'react';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
// class TelemetryProviderC extends Component {
//   state = {
//     initialized: false
//   };

//   componentDidMount() {
//     const { history } = this.props;
//     const { initialized } = this.state;
//     const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
//     if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
//       ai.initialize(AppInsightsInstrumentationKey, history);
//       this.setState({ initialized: true });
//     }

//     this.props.after();
//   }

//   render() {
//     const { children } = this.props;
//     return (
//       <Fragment>
//         {children}
//       </Fragment>
//     );
//   }
// }

export const TelemetryProvider = ({history, instrumentationKey, children, after}) => {
  const [initialized,setInitialized] = useState(false);

  useEffect(()=>{
    const AppInsightsInstrumentationKey = instrumentationKey; // PUT YOUR KEY HERE
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      setInitialized(true);
    }

    after();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);