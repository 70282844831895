import './index.css';
import './custom.scss';
import 'bootstrap/dist/js/bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import {
  React,
  StrictMode,
} from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (!Intl.PluralRules) {
  require("intl-pluralrules");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  //require('@formatjs/intl-relativetimeformat/dist/include-aliases'); // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  require("@formatjs/intl-relativetimeformat/locale-data/sv"); // Add locale data
  require("@formatjs/intl-relativetimeformat/locale-data/en"); // Add locale data
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
      <App />
  </StrictMode>
)
//createRoot(document.getElementById("root")).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
