import Loading from "components/Shared/Loading";
import { useEffect } from "react";
import { v4 } from "uuid";

const Redirect = () => {
  useEffect(() => {
    const nonce = v4();
    sessionStorage.setItem("nonce", nonce);
    const baseUrl = `https://${window.location.hostname}/oidc-callback?nonce=${nonce}`;
    window.location = `${window._env_.AUTH_URL}/?redirect=${baseUrl}`;
  }, []);
  return <Loading />;
};
export default Redirect;
